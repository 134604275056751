<template>
  <div class="chartContainer">
      <apexchart height="100%" ref="chart" width="100%" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style scoped>
  .chartContainer {
    height: 100%;
    min-height: 350px;
  }
</style>

<script>



export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    chartLabel: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      default: () => ['#10B981', '#F05252', '#3F83F8', '#FFC145', '#60435F', "#1D4E89", "#D97706", "#34D399", "#A78BFA", "#F87171" ]
    }
  },
  data() {

    return {

      chartOptions: {
        colors: this.colors,
        chart: {
          type: 'donut',
        },
        title: {
          text: this.chartLabel
        },
        labels: this.labels,
        responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom'
                }
              }
            }]
      },
    }
  },
  watch: {
    labels(newLabels) {
      this.updateChart(newLabels);
    },
    series(newSeries) {
      if (this.$refs.chart) {
        this.$refs.chart.updateSeries(newSeries);
      }
    }
  },
  methods: {

    updateChart(newLabels) {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions({
          xaxis: {
            categories: newLabels
          },

        });
      }
    }
  }
}
</script>
