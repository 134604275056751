<script setup>
import BaseIcon from './BaseIcon.vue';
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { onMounted, onBeforeUnmount, ref, watch, computed } from 'vue';
import { useMainStore } from "@/stores/main.js";
import { pb } from "@/stores/main.js";

const dropdownRef = ref(null);
const isOpen = ref(false);
const mainStore = useMainStore();
const selectedOption = ref(mainStore.selectedCompany);

// Get company logo URL using PocketBase
const companyLogoUrl = ref(null);
const updateLogoUrl = () => {
  if (selectedOption.value && selectedOption.value.logo) {
    companyLogoUrl.value = pb.getFileUrl(selectedOption.value, selectedOption.value.logo);
  } else if (selectedOption.value) {
    companyLogoUrl.value = `https://api.dicebear.com/7.x/initials/svg?radius=0&seed=${selectedOption.value.name[0]}`
  }
};

// Computed property to check if dropdown should be interactive
const isDropdownInteractive = computed(() => mainStore.companies.length > 1);

watch(() => mainStore.selectedCompany, (newVal) => {
  selectedOption.value = newVal;
  updateLogoUrl();
}, { immediate: true });

const closeDropdownIfClickedOutside = (event) => {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target)) {
    isOpen.value = false;
  }
};

const toggleDropdown = () => {
  if (isDropdownInteractive.value) {
    isOpen.value = !isOpen.value;
  }
};

const onOptionClick = (option) => {
  mainStore.setSelectedCompany(option);
  selectedOption.value = option;
  updateLogoUrl();
  isOpen.value = false;
};

onMounted(() => {
  document.addEventListener('click', closeDropdownIfClickedOutside);
  updateLogoUrl();
});

onBeforeUnmount(() => {
  document.removeEventListener('click', closeDropdownIfClickedOutside);
});
</script>

<template>
  <div class="relative my-2" ref="dropdownRef">
    <button
      @click="toggleDropdown"
      :class="['w-full rounded-lg inline-flex items-center transition-shadow duration-200', isDropdownInteractive ? 'hover:shadow-lg cursor-pointer' : 'cursor-default']"
      :disabled="!isDropdownInteractive"
    >
      <img v-if="companyLogoUrl" :src="companyLogoUrl" alt="Logo" class="w-12 h-12 mr-2 p-1 rounded-full" />
      <span class="flex-1">
        {{ selectedOption ? selectedOption.label : 'Select a company' }}
      </span>
      <BaseIcon
        v-if="isDropdownInteractive"
        :path="isOpen ? mdiChevronUp : mdiChevronDown"
        :size="24"
        class="ml-auto mr-2"
      />
    </button>

    <div v-if="isOpen && isDropdownInteractive" class="mt-2 py-2 bg-white border rounded-lg shadow-lg absolute left-0 min-w-max w-full">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
        <li
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer"
          v-for="(option, index) in mainStore.companies"
          :key="index"
          @click="onOptionClick(option)"
        >
          {{ option.label ? option.label : option.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
/* Tailwind CSS classes for styling */
</style>
